@import url('https://fonts.googleapis.com/css?family=Montserrat');
@import './common/_grid';
@import './common/_color';

* {
    outline-width: 0;
    font-family: 'Montserrat';
}

html {
    display: flex;
    flex-flow: column;
    min-height: 100vh;
    min-height: -webkit-fill-available;
}

body,
#root {
    height: 100%;
    display: flex;
    flex-grow: 1;
    flex-flow: column;
}

.App {
    flex-grow: 1;
    background-color: $white;
    overflow: hidden;
    background: $dark_blue;
    background: $colored_gradient;
}

/************************** Custom CSS *****************************/
.cursor-pointer {
    cursor: pointer;
}

.colored-text {
    background: -webkit-linear-gradient(145deg, rgba($dark_red, 1) 0%, rgba($dark_blue, 1) 100%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}

/*********************** Bootstrap Redesign ************************/
// btn
.btn-primary,
.btn-secondary {
    margin: 5px auto;
    width: 100%;
    border-radius: 25px;
    font-size: 18px;
    padding: 9px;
    border: none;
    position: relative;

    svg {
        position: absolute;
        right: 20px;
        top: 50%;
        transform: translateY(-50%);
    }
}

.btn-primary {
    background-color: $blue;

    &:hover {
        background-color: $dark_blue;
        box-shadow: $shadow;
    }
}

.btn-secondary {
    background-color: $red;

    &:hover {
        background-color: $dark_red;
        box-shadow: $shadow;
    }
}

// input
.text-input {
    width: 100%;
    border: 1px solid rgba($black, 0.2);
    border-radius: 25px;
    padding: 10px 20px;
    color: $blue;
    font-size: 18px;
    margin: 5px auto;
    text-align: center;
    transition: 0.25s;
    padding: 8px;
    -webkit-appearance: none;

    &:hover,
    &:focus {
        border-color: transparent;
        box-shadow: 0 0 6px rgba($black, 0.2);
    }

    &::placeholder {
        color: $blue;
    }
}

// toast
.toast {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    max-width: none;
    border: none;
    border-radius: unset;
    color: $white;
    background-color: transparent;

    &.show {
        z-index: 2001;
    }

    &.error {
        background-color: $red;
    }

    &.success {
        background-color: $dark_green;
    }

    &.error,
    &.success {
        .toast-header .close {
            opacity: 1;
        }
    }

    .toast-header {
        background-color: transparent;
        border-bottom: none;
        color: $white;
        padding: 10px 10px 0 10px;

        .close {
            opacity: 0;
            color: $white;
        }
    }

    .toast-body {
        padding: 0 10px 10px 10px;
    }
}