@import '../common/_color';

#NearbySearch {
    margin-top: 10%;

    h1 {
        text-align: center;
        font-size: 60px;
        color: $white;
        margin-bottom: 20px;
    }

    input[type=text],
    input[type=number] {
        background-color: transparent;
        border-color: $white;
        color: $white;

        &::placeholder {
            color: $white;
        }
    }

    .bpzdHV {
        .hijnLS {
            width: 100%;
            position: relative;
            top: initial;
            box-shadow: none;
            background-color: $white;
            margin: 5px auto;
            padding: 10px;
            border-radius: 10px;
            box-shadow: $shadow;
            transition: 0.25s;

            &.invalid {
                .sc-htpNat {
                    color: $dark_red;
                }
            }

            .sc-htpNat {
                width: 100%;
                font-size: 15px;
                padding: 10px;
                color: $dark_blue;
                font-weight: bold;
                background-color: transparent;

                &:hover {
                    background-color: rgba($black, 0.1);
                }
            }
        }
    }
}