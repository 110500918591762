/******************** Color ************************/
// generic
$black: #000;

// white
$dark_white: #ededed;
$white: #fff;
$light_white: #f7f7f7;

// gray
$dark_gray: #616060;
$gray: #7C7C7C;
$light_gray: #C7C7C7;

// blue
$dark_blue: #1488fc;
$blue: #3a9bfc;
$light_blue: #84B4FF;

// green
$dark_green: #2fb551;
$green: #3fd163;
$light_green: #6cd486;

// indigo
$dark_indigo: #4a48b5;
$indigo: #6e6ce6;
$light_indigo: #8d8ce6;

// orange
$dark_orange: #e68702;
$orange: #fca319;
$light_orange: #f7b245;

// pink
$dark_pink: #e8274c;
$pink: #fa4368;
$light_pink: #fc6a88;

// purple
$dark_purple: #9b49c4;
$purple: #c86af7;
$light_purple: #d487fa;

// red
$dark_red: #fa4e43;
$red: #f7776f;
$light_red: #faa5a0;

// teal
$dark_teal: #4fb5e3;
$teal: #6ed1fa;
$light_teal: #a4e3fc;

// yellow
$dark_yellow: #e8ba02;
$yellow: #fcd617;
$light_yellow: #fce46a;

/******************** Shadow ************************/
$shadow: 0 3px 6px rgba($black, 0.2);
$deep_shadow: 0 5px 10px rgba($black, 0.4);

/******************** Gradient ************************/
$colored_gradient: linear-gradient(145deg, rgba($dark_blue, 1) 0%, rgba($dark_red, 1) 100%)