@import '../common/_color';
@import '../common/grid';

#board {
    flex-grow: 1;
    display: flex;
    flex-flow: column;
    height: 100%;
    padding: 10px;
    max-width: $sm;
    margin: auto;

    @media (min-width: $sm) {
        max-height: $lg;
    }
}

#cardLoader,
#noCards {
    margin: 20vh auto auto auto;
    text-align: center;
    line-height: 40px;
    color: $white;
}

#cardLoader {
    &>div {
        font-size: 20px;
    }
}

#noCards {
    font-size: 20px;
}

#cards {
    display: flex;
    height: 100%;
    flex-grow: 1;
    position: relative;
    justify-content: center;

    .swipe-card {
        display: flex;
        flex-flow: column;
        width: 100%;
        max-width: 500px;
        height: 100%;
        position: absolute;
        border-radius: 10px;
        background-color: white;
        overflow: hidden;
        padding: 10px;
        transition: ease-in-out;

        &:first-of-type {
            box-shadow: $deep_shadow;
        }

        .card-info {
            display: flex;
            flex-flow: column;
            height: 100%;

            .place-name {
                font-weight: bold;
                color: $white;
                z-index: 2;
                text-shadow: $shadow;
            }

            .place-photos-cont {
                position: absolute;
                top: 0;
                left: 0;
                height: 100%;
                width: 100%;

                .place-photo {
                    height: 100%;
                    display: flex;

                    img {
                        width: 100%;
                        height: 100%;
                        object-fit: cover;
                    }
                }

                &.no-image {
                    background-color: $blue;
                    display: flex;
                    flex-flow: column;
                    font-size: 50px;
                    color: $white;
                    justify-content: center;
                    text-align: center;
                    line-height: 50px;

                    svg {
                        margin: 0 auto;
                    }
                }
            }

            .place-rating {
                position: relative;
                font-size: 20px;
                font-weight: bold;
                color: white;
                text-shadow: $shadow;
            }
        }

        .card-swipe-direction {
            height: 100%;
            width: 100%;
            position: absolute;
            top: 0;
            left: 0;
            z-index: -1;

            &.right {
                background-color: $green;

                .direction-right {
                    display: block;
                }
            }

            &.left {
                background-color: $red;

                .direction-left {
                    display: block;
                }
            }

            .direction-left,
            .direction-right {
                display: none;
                position: absolute;
                top: 40%;
                left: 50%;
                transform: translate(-50%, -50%);
                font-size: 100px;
                color: $white;
            }
        }
    }
}

#boardControl {
    display: flex;
    justify-content: space-between;
    position: relative;
    margin-top: auto;

    .board-control-main-btn {
        height: 60px;
        width: 60px;
        border-radius: 50%;
        display: flex;
        justify-content: center;
        font-size: 25px;
        transition: 0.25s;
        background-color: $white;
        box-shadow: $shadow;

        &>svg {
            margin: auto;
        }

        &#boardDislike {
            color: $red;

            &:hover {
                color: $white;
                background-color: $red;
                border-color: transparent;
                box-shadow: $shadow;
            }
        }

        &#boardLike {
            color: $blue;

            &:hover {
                color: $white;
                background-color: $blue;
                border-color: transparent;
                box-shadow: $shadow;
            }
        }

        &#boardDone {
            width: auto;
            height: auto;
            padding: 5px 20px;
            border-radius: 25px;
            align-self: start;
            margin-top: auto;
            font-size: 18px;
            font-weight: bold;

            &:hover {
                background: $colored_gradient;

                span {
                    color: $white;
                    background: transparent;
                    -webkit-text-fill-color: $white;
                }
            }
        }
    }
}

#resultPlace {
    flex: 1;
    border-radius: 10px;
    overflow: hidden;
    background-color: rgba($light_gray, 0.4);
    z-index: 1;

    &>.col {
        padding: 10px;
        display: flex;
        flex-flow: column;
    }

    .result-banner {
        position: absolute;
        top: 20px;
        right: 20px;
        z-index: 1;
        border: 2px solid $green;
        color: $white;
        border-radius: 10px;
        background-color: $green;

        h1 {
            font-size: 20px;
            font-weight: bold;
            margin: 5px 10px;
        }
    }

    .place-name {
        font-size: 40px;
        color: $white;
        font-weight: bold;
        text-shadow: $shadow;
    }

    .place-photo {
        position: absolute;
        top: 0;
        left: 0;
        height: 100%;

        img {
            object-fit: cover;
            height: 100%;
            width: 100%;

            &:not(.show) {
                display: none;
            }
        }
    }

    .result-bottom-sec {
        margin-top: auto;
        z-index: 1;

        &>.col {
            display: flex;
        }

        .phone-number {
            height: 43px;
            width: 43px;
            border-radius: 50%;
            margin-right: 10px;
            flex-shrink: 0;

            svg {
                right: 50%;
                transform: translate(50%, -50%);
            }
        }

        .btn-primary {
            background-color: $white;
            font-weight: bold;
            $color: $blue;

            &:hover {
                background: $colored_gradient;

                .colored-text {
                    -webkit-text-fill-color: $white;
                }
            }

            svg {
                color: $blue;
            }
        }
    }
}

#loadingOverlay {
    position: fixed;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    background-color: rgba($black, 0.7);
    z-index: 2000;
    color: $white;
    display: none;

    &>.col {
        display: flex;
        justify-content: center;
        flex-flow: column;
        height: 100%;
    }

    .col {
        text-align: center;
        font-size: 20px;
    }

    .spinner-border {
        margin: auto;
    }
}