@import '../common/_grid';
@import '../common/_color';

.navbar {
    padding: 10px 15px;
    margin: -10px -10px 0 -10px;
    background-color: transparent !important;
    z-index: 1000;

    #optionMenuTrigger {
        color: $white !important;
    }
}

.logo {
    font-weight: bold;
    font-size: 30px;
    color: $blue !important;
    padding: 0;

    &.logo-white {
        color: $white !important;
    }

    .navbar-brand-r {
        color: $red;
    }
}

#optionMenuTrigger {
    background-color: transparent;
    color: $blue;
    width: auto;
    margin-right: 0;
    padding: 0;

    svg {
        right: 0;
    }
}

.modal-dialog {
    height: 100%;
    margin: auto;

    @media (min-width: $sm) {
        max-width: $sm;
        max-height: $lg;
    }

    .modal-content {
        height: 100%;
        border: none;
        border-radius: 0;

        form {
            display: flex;
            flex-flow: column;
            flex: 1;
            height: 100%;
        }
    }

    .modal-title {
        display: flex;
        flex-flow: row;
        width: 100%;

        .modal-sub-text {
            margin-left: auto;
            padding: 9px;
            color: $gray;
            font-size: 18px;
        }
    }

    .modal-body {
        color: $gray;
        text-align: center;
        overflow-y: scroll;

        &>.row>div:not(:last-child) {
            margin-bottom: 40px;
        }

        .row {
            justify-content: center;
        }

        .option-title {
            color: $dark_blue;
            font-weight: bold;
            font-size: 20px;
            margin-bottom: 10px;
        }

        // Filter Disabled Message
        .filter-disabled-msg {
            background-color: $gray;
            color: $white;
            padding: 10px;
            margin-top: -1em;
        }

        // Search Radisu
        .search-radius {
            .form-group {
                display: flex;
                flex-flow: row;
                justify-content: center;

                select {
                    width: auto;
                    height: auto;
                    margin-right: 10px;
                }

                span {
                    height: 35px;
                    line-height: 35px;
                }
            }
        }

        // Restaurant Type
        .search-type {
            #restaurantTypeCont {
                flex-flow: row;
            }

            .type-radio-cont {
                display: inline-flex;
                padding: 5px 20px;
                margin: 3px 10px;
                background-color: $light_blue;
                color: $white;
                border-radius: 25px;

                &:hover {
                    background-color: $blue;
                }

                &.disabled {
                    background-color: $light_gray;
                }

                &.selected {
                    background-color: $dark_blue;

                    &.disabled {
                        background-color: $dark_gray;
                    }
                }

                input {
                    display: none;
                }
            }
        }

        // Max Price
        .max-price {
            .price-slider-cont {
                display: flex;
                flex-flow: column;

                .price-icons {
                    display: flex;
                    margin: auto;
                    width: 75px;
                    text-align: center;
                    justify-content: center;
                    margin-bottom: 10px;

                    svg {
                        margin: 0 1px;

                        &.dollar-0 {
                            color: $yellow;
                        }

                        &.dollar-1 {
                            color: $light_orange;
                        }

                        &.dollar-2 {
                            color: $dark_orange;
                        }

                        &.dollar-3 {
                            color: $red;
                        }

                        &.dollar-4 {
                            color: $dark_red;
                        }
                    }
                }

                .rc-slider {
                    &.rc-slider-disabled {
                        .rc-slider-track {
                            background-color: $light_gray;
                        }

                        .rc-slider-handle {
                            border-color: $gray;
                        }
                    }

                    .rc-slider-track {
                        background-color: $light_blue;
                    }

                    .rc-slider-handle {
                        height: 20px;
                        width: 20px;
                        margin-top: -7.5px;
                        border-color: $blue;
                    }
                }
            }
        }

        // Open Now
        .open-now {
            .toggle-cont {
                height: 35px;
                width: 100px;
                border-radius: 25px;
                margin: auto;
                transition: 0.25s;
                position: relative;

                &.true {
                    background-color: $green;

                    .toggle-circle {
                        right: 5px;
                    }
                }

                &.false {
                    background-color: $gray;

                    .toggle-circle {
                        right: 70px;
                    }
                }

                &.disabled {
                    background-color: $light_gray;
                }

                .toggle-circle {
                    width: 25px;
                    height: 25px;
                    border-radius: 50%;
                    background-color: rgba($white, 0.7);
                    transition: 0.25s;
                    position: absolute;
                    top: 50%;
                    transform: translateY(-50%);
                    ;
                }
            }
        }
    }

    .modal-footer {
        &.swiping {
            .btn-primary {
                display: none;
            }

            .btn-secondary {
                width: auto;
                flex: 1;
                border-radius: 25px;
            }
        }

        .btn-primary {
            width: auto;
            flex: 1;
            margin-left: 10px;
            border-radius: 10px 25px 25px 10px;
        }

        .btn-secondary {
            height: 45px;
            width: 80px;
            border-radius: 25px 10px 10px 25px;

            svg {
                right: 50%;
                transform: translate(50%, -50%);
            }
        }
    }
}

.rc-slider-tooltip {
    z-index: 1051;
}